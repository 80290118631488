import React, { createContext, useReducer } from "react";

const initialState = {
  darkmode: false,
};

const themeReducer = (state, action) => {
  switch (action.type) {
    case "DARKMODE":
      return { ...state, darkmode: action.payload };
    default:
      return state;
  }
};

export const ThemeContext = createContext({
  dispatch: null,
  state: initialState,
});

export function ThemeProvider({ children }) {
  const [state, dispatch] = useReducer(themeReducer, initialState);

  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {children}
    </ThemeContext.Provider>
  );
}
