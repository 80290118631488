
import React from "react"
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from "./src/store/theme"

import "./src/html.css"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <CookiesProvider>
      {element}
    </CookiesProvider>
  </ThemeProvider>
)
