import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useIntl } from "gatsby-plugin-intl";

export default () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: "title_picpick" });
  const metaDescription = intl.formatMessage({ id: "title_picpick" });
  const lang = intl.locale;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
    </Helmet>
  );
};
